import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))

const StepResults = ({ total, utm }) => {
    const [options] = useContext(OptionsContext)

    const journeyLink = options.journey.mainJourney.link
    const ctaData = {
        url: journeyLink,
        title: 'Get a quote'
    }

    return (
        <LargeCard className={'slick-card'}>
            <div ref={total}></div>

            <CtaContainer align={'centered'}>
                <ButtonLink data={ctaData} size={'lg'} icon={'arrow'} utm={utm} />
            </CtaContainer>
        </LargeCard>
    )
}

export default StepResults
